import * as React from 'react'
import { Box, Button, Container, Flex, Heading, Text } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import Base from '../base'
import BackSVG from '../icons/back.svg'
import CloseSVG from '../icons/close.svg'
import MenuSVG from '../icons/menu.svg'
import { StaticImage } from 'gatsby-plugin-image'
import { isBaseRoute } from '../helpers/routes'
import { useContext } from 'react'
import { WindowHeightContext } from '../context/windowHeightContext'

const BasePage = (props) => {
	const height = useContext(WindowHeightContext)

	const {
		parent,
		theme,
		backBtn,
		onBack,
		closeBtn,
		flex,
		onClose,
		noHeader,
		showMenuBtn,
		noPadding,
		showBottomDuck,
		title,
		titleView,
		largeTitle,
		rightBtn,
		primaryBtn,
		secondaryBtn,
		access,
		children,
		fullWidthActionBtns,
		closeBtnText,
		additionalTopPadding,
		hideSidebar,
		tip
	} = props
	const isBase = isBaseRoute(
		typeof window !== 'undefined' ? window.location.pathname : ''
	)

	return (
		<Base
			access={
				access === null || typeof access === 'undefined' ? 5 : access
			}
			theme={theme}
			parent={parent}
			hideSidebar={hideSidebar}
		>
			<Container
				variant={theme || 'light'}
				actualheight={height}
				position="relative"
				display={flex ? 'flex' : 'block'}
				flexDirection="column"
				p={noPadding ? '0px' : '20px'}
			>
				<Flex
					flex="1"
					direction="column"
					pt={{
						lg: fullWidthActionBtns ? '100px' : '60px',
						base: additionalTopPadding ? '60px ' : '10px'
					}}
				>
					<Flex
						zIndex="3"
						minH="48px"
						display={noHeader ? 'none' : 'flex'}
						pt={noPadding ? '20px' : '0'}
						px={noPadding ? '20px' : '0'}
						style={
							fullWidthActionBtns
								? {
										position: 'fixed',
										left: '60px',
										right: '60px',
										top: '60px'
								  }
								: {}
						}
					>
						{!!backBtn && (
							<Button
								variant="icon"
								opacity={isBase ? { lg: '0', base: '1' } : '1'}
								onClick={() =>
									typeof onBack === 'function'
										? onBack()
										: navigate(backBtn)
								}
								minW="48px"
								minH="48px"
							>
								<BackSVG />
							</Button>
						)}
						<Flex
							flex="1"
							alignItems="center"
							justifyContent="center"
							ml={!!backBtn ? '0' : '48px'}
							mr={!!closeBtn || !!rightBtn ? '0' : '48px'}
							maxW="100%"
							w="100%"
							overflow="hidden"
						>
							{!!title ? (
								<Heading
									as="h4"
									color={
										theme === 'light' ? 'black' : 'white'
									}
								>
									{title}
								</Heading>
							) : (
								titleView
							)}
						</Flex>
						{!!closeBtn && (
							<Button
								variant={closeBtnText ? 'medium' : 'icon'}
								onClick={() => {
									if (onClose) {
										onClose()
									} else {
										navigate(closeBtn)
									}
								}}
							>
								{closeBtnText || <CloseSVG />}
							</Button>
						)}

						{rightBtn}
					</Flex>
					{!!largeTitle && (
						<Flex justifyContent="center" mt="10px">
							<Heading
								as="h1"
								color={theme === 'light' ? 'black' : 'white'}
							>
								{largeTitle}
							</Heading>
						</Flex>
					)}
					<Box flex={flex ? '1' : ''}>{children}</Box>
					{showMenuBtn && (
						<Button
							variant="icon"
							position="fixed"
							right="20px"
							bottom="20px"
							zIndex="9"
							display={{ lg: 'none', base: 'flex' }}
							onClick={() => navigate('/')}
						>
							<MenuSVG />
						</Button>
					)}
					{showBottomDuck && (
						<Flex>
							<StaticImage
								style={{ width: 94, height: 94 }}
								alt="Duck Bottom"
								src="../images/duck-left.png"
							/>
						</Flex>
					)}
				</Flex>
				<Flex direction="column">
					{primaryBtn && (
						<Flex flex="1" mt="40px">
							{primaryBtn}
						</Flex>
					)}
					{secondaryBtn && <Flex flex="1">{secondaryBtn}</Flex>}
					{tip && (
						<Text variant="m" marginBottom={20} textAlign="center">
							{tip}
						</Text>
					)}
				</Flex>
			</Container>
		</Base>
	)
}

export default BasePage
