import React, { useRef, useCallback, useState, useEffect } from 'react'
import { Text, Button } from '@chakra-ui/react'
import Modal from './modal'

const TimeoutModal = ({ onReset }) => {
	const [modal, setModal] = useState(false)
	const [countdown, setCountdown] = useState(10)

	const timer = useRef()
	const miniTimer = useRef()

	const restartInactiveTimer = useCallback(() => {
		setModal(false)
		clearInterval(miniTimer.current)
		timer.current = setTimeout(refreshTimer, 30000)
	}, [refreshTimer, refreshCountdown, timer])

	const refreshCountdown = useCallback(() => {
		setCountdown((prevCount) => {
			if (prevCount === 1) {
				// alert('reset')
				onReset()
			}
			return prevCount - 1
		})
	}, [onReset, countdown, setCountdown])

	useEffect(() => {
		restartInactiveTimer()
		return () => {
			clearTimeout(timer.current)
			clearTimeout(miniTimer.current)
		}
	}, [])

	const refreshTimer = useCallback(() => {
		setModal(true)
		setCountdown(10)
		miniTimer.current = setInterval(refreshCountdown, 1000)
	}, [refreshCountdown, miniTimer])

	return (
		<Modal
			opened={modal && countdown > -1}
			onClose={restartInactiveTimer}
			title="Are you still there?"
			isPink
			offsetLeft={false}
			primaryBtn={
				<Button variant="primary" onClick={restartInactiveTimer}>
					Continue
				</Button>
			}
		>
			<Text
				variant="xl"
				fontWeight="700"
				mt="15px"
				px="20px"
				textAlign="center"
			>
				{countdown}
			</Text>
		</Modal>
	)
}

export default TimeoutModal
